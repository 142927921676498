import React, { useState, useEffect, useContext } from "react";
import { TechContext } from "../../context/technologyContext";
import { LensTechnogolyData } from "../../utils/commonInterfaces";
import { MaterialContext } from "../../context/materialContext";
import {
  neoSpace153Coatings,
  withHCCoatings,
  finishedSingleVisionTechCoating,
} from "../../utils/constants";
import { SECTION_HEADINGS } from "../../utils/constants";
import Poppers from "../../widgets/Poppers";
import { TreatmentContext } from "../../context/treatmentContext";
import { ColorContext } from "../../context/colorContext";
import { CoatingContext } from "../../context/coatingContext";
import { useTranslation } from 'react-i18next';
import {
  driveZClearSightTreatments,
  hcTreatments,
  hmcBluTechTreatments,
  neoSpace153HMCTreatments,
  polycarbonateTreatments,
  material16Treatments,
  ultraThinTreatments,
  clearTransitionTreatments,
  neoDigiClassicTreatments,
  neoDigiPolycarbonateTreatments,
  neoUnoPolycarbonateTreatments,
  geryColor,
  clearColor,
  brownGreyColor,
  allColor,
  mirrorColor,
  kBiFocalDigitalTreatments,
  kBiFocalClearSunSafeTreatments,
} from "../../utils/constants";

interface LensParametersList {
  coatings: LensTechnogolyData[];
  materials: LensTechnogolyData[];
  progressiveTechs: LensTechnogolyData[];
  ultimaTechs: LensTechnogolyData[];
}

export interface RightPanelMaterialData {
  selectedMaterialValue: string;
  isFullWidth: boolean;
}
const LensModels: React.FC<RightPanelMaterialData> = (props:any) => {
  const {isFullWidth} = props;
  const [lensParamsList, setLensParamsList] = useState<LensParametersList>({
    coatings: [],
    materials: [],
    progressiveTechs: [],
    ultimaTechs: [],
  });
  const techContext = useContext(TechContext);
  const coatingContext = useContext(CoatingContext);
  const treatmentContext = useContext(TreatmentContext);
  const colorContext = useContext(ColorContext);
  const materialContext = useContext(MaterialContext);
  const [treatments, setTreatments] = useState<LensTechnogolyData[]>([]);
  const [colors, setColors] = useState<LensTechnogolyData[]>([]);
  const [isEnableColor, setIsEnableColor] = useState(false);
  const { t } = useTranslation()

  useEffect(() => {
    if (techContext && materialContext) {
      let techData = techContext.technology;
      let tmpMaterials = techData.filter((data) => data.key === "material");
      let selectedTechnology = [
        "Neo Ergo",
        "Neo Space",
        "Neo Binocs",
        "Neo Pro",
        "Neo Expert",
        "Single Vision",
      ].includes(techContext.technology[0].value);
      let tmpCoatings = techData.filter((data) => data.key === "coating");
      // let tmpCoatings =
      //   techContext.technology[0].value !== "Initia"
      //     ? materialContext.selectedMaterial === "index1.53"
      //       ? neoSpace153Coatings
      //       : ["classic", "polycarbonate"].includes(
      //           materialContext.selectedMaterial
      //         ) && techContext?.technology[0].value === "Finished Single Vision"
      //       ? finishedSingleVisionTechCoating
      //       : materialContext.selectedMaterial === "classic" &&
      //         techContext?.technology[0].value === "DT Bi Focal"
      //       ? withHCCoatings
      //       : ["classic", "polycarbonate"].includes(
      //           materialContext.selectedMaterial
      //         ) && selectedTechnology
      //       ? withHCCoatings
      //       : techData.filter((data) => data.key === "coating")
      //     : neoSpace153Coatings;
      let tmpPrTechs = techData.filter((data) => data.key === "prTech");
      let tmpUltimaTechs = techData.filter((data) => data.key === "ultimaTech");
      setLensParamsList((prevList) => ({
        ...prevList,
        coatings: tmpCoatings,
        materials: tmpMaterials,
        progressiveTechs: tmpPrTechs,
        ultimaTechs: tmpUltimaTechs,
      }));
    }
  }, [techContext, materialContext]);

  const filterByCoating = (item: string) => {
    switch (item) {
      case "hc":
        if (
          techContext?.technology[0].value === "Ace Bi Focal" ||
          techContext?.technology[0].value === "Finished Single Vision" ||
          techContext?.technology[0].value === "K Bi Focal"
        ) {
          setTreatments(ultraThinTreatments);
        } else if (techContext?.technology[0].value === "DT Bi Focal") {
          setTreatments(clearTransitionTreatments);
        } else if (
          techContext?.technology[0].value === "K Bi Focal (Digital)"
        ) {
          setTreatments(kBiFocalDigitalTreatments);
        } else if (
          materialContext.selectedMaterial === "classic" &&
          techContext?.technology[0].value === "Single Vision"
        ) {
          setTreatments(neoDigiClassicTreatments);
        } else {
          setTreatments(hcTreatments);
        }
        break;
      case "adv":
        if (
          (techContext?.technology[0].value === "Finished Single Vision" &&
            materialContext.selectedMaterial === "classic") ||
          materialContext.selectedMaterial === "polycarbonate"
        ) {
          setTreatments(ultraThinTreatments);
        }
        break;
      case "hmc":
        if (
          techContext?.technology[0].value === "Ace Bi Focal" ||
          techContext?.technology[0].value === "K Bi Focal"
        ) {
          setTreatments(ultraThinTreatments);
        } else if (
          techContext?.technology[0].value === "K Bi Focal (Digital)"
        ) {
          setTreatments(kBiFocalDigitalTreatments);
        } else if (
          techContext?.technology[0].value === "DT Bi Focal" &&
          materialContext.selectedMaterial === "classic"
        ) {
          setTreatments(clearTransitionTreatments);
        } else if (materialContext.selectedMaterial === "index1.53") {
          setTreatments(neoSpace153HMCTreatments);
        } else if (
          materialContext.selectedMaterial === "classic" &&
          (techContext?.technology[0].value === "Neo Digi" ||
            techContext?.technology[0].value === "Neo Uno" ||
            techContext?.technology[0].value === "Finished Single Vision" ||
            techContext?.technology[0].value === "Single Vision")
        ) {
          setTreatments(neoDigiClassicTreatments);
        } else if (
          materialContext.selectedMaterial === "polycarbonate" &&
          (techContext?.technology[0].value === "Neo Digi" ||
            techContext?.technology[0].value === "DT Bi Focal" ||
            techContext?.technology[0].value === "Finished Single Vision")
        ) {
          setTreatments(neoDigiPolycarbonateTreatments);
        } else if (
          materialContext.selectedMaterial === "polycarbonate" &&
          (techContext?.technology[0].value === "Neo Uno" ||
            techContext?.technology[0].value === "Neo Pro" ||
            techContext?.technology[0].value === "Neo Expert" ||
            techContext?.technology[0].value === "Single Vision")
        ) {
          setTreatments(neoUnoPolycarbonateTreatments);
        } else if (materialContext.selectedMaterial === "polycarbonate") {
          setTreatments(polycarbonateTreatments);
        } else if (materialContext.selectedMaterial === "index1.6") {
          setTreatments(material16Treatments);
        } else if (materialContext.selectedMaterial === "ultraThin") {
          setTreatments(ultraThinTreatments);
        } else if (
          materialContext.selectedMaterial === "classic" &&
          techContext?.technology[0].value === "Initia"
        ) {
          setTreatments(ultraThinTreatments);
        } else {
          setTreatments(hmcBluTechTreatments);
        }
        break;
      case "blueTech+":
        if (
          techContext?.technology[0].value === "Ace Bi Focal" ||
          techContext?.technology[0].value === "K Bi Focal"
        ) {
          setTreatments(ultraThinTreatments);
        } else if (
          techContext?.technology[0].value === "K Bi Focal (Digital)"
        ) {
          setTreatments(kBiFocalDigitalTreatments);
        } else if (
          materialContext.selectedMaterial === "polycarbonate" &&
          (techContext?.technology[0].value === "Neo Digi" ||
            techContext?.technology[0].value === "DT Bi Focal" ||
            techContext?.technology[0].value === "Finished Single Vision")
        ) {
          setTreatments(neoDigiPolycarbonateTreatments);
        } else if (
          materialContext.selectedMaterial === "polycarbonate" &&
          (techContext?.technology[0].value === "Neo Uno" ||
            techContext?.technology[0].value === "Neo Pro" ||
            techContext?.technology[0].value === "Neo Expert" ||
            techContext?.technology[0].value === "Single Vision")
        ) {
          setTreatments(neoUnoPolycarbonateTreatments);
        } else if (materialContext.selectedMaterial === "polycarbonate") {
          setTreatments(polycarbonateTreatments);
        } else if (
          materialContext.selectedMaterial === "classic" &&
          (techContext?.technology[0].value === "Neo Digi" ||
            techContext?.technology[0].value === "Neo Uno" ||
            techContext?.technology[0].value === "Finished Single Vision" ||
            techContext?.technology[0].value === "Single Vision")
        ) {
          setTreatments(neoDigiClassicTreatments);
        } else if (
          (materialContext.selectedMaterial === "index1.6" ||
            materialContext.selectedMaterial === "thinLens" ||
            materialContext.selectedMaterial === "classic") &&
          (techContext?.technology[0].value === "Ultima" ||
            techContext?.technology[0].value === "DT Bi Focal")
        ) {
          setTreatments(clearTransitionTreatments);
        } else if (materialContext.selectedMaterial === "index1.6") {
          setTreatments(material16Treatments);
        } else if (
          materialContext.selectedMaterial === "ultraThin" ||
          (materialContext.selectedMaterial === "index1.56" &&
            techContext?.technology[0].value === "Computer Lens")
        ) {
          setTreatments(ultraThinTreatments);
        } else {
          setTreatments(hmcBluTechTreatments);
        }
        break;
      case "drivez":
        if (
          techContext?.technology[0].value === "Ace Bi Focal" ||
          techContext?.technology[0].value === "K Bi Focal"
        ) {
          setTreatments(ultraThinTreatments);
        } else if (
          techContext?.technology[0].value === "K Bi Focal (Digital)"
        ) {
          setTreatments(kBiFocalClearSunSafeTreatments);
        } else if (
          (materialContext.selectedMaterial === "polycarbonate" ||
            materialContext.selectedMaterial === "classic" ||
            materialContext.selectedMaterial === "thinLens") &&
          (techContext?.technology[0].value === "drivEZ" ||
            techContext?.technology[0].value === "svRxDrivEZ")
        ) {
          setTreatments(driveZClearSightTreatments);
        } else if (materialContext.selectedMaterial === "polycarbonate") {
          setTreatments(clearTransitionTreatments);
        } else if (
          (materialContext.selectedMaterial === "index1.6" ||
            materialContext.selectedMaterial === "thinLens" ||
            materialContext.selectedMaterial === "classic") &&
          (techContext?.technology[0].value === "Ultima" ||
            techContext?.technology[0].value === "DT Bi Focal")
        ) {
          setTreatments(clearTransitionTreatments);
        } else if (materialContext.selectedMaterial === "index1.6") {
          setTreatments(material16Treatments);
        } else if (materialContext.selectedMaterial === "ultraThin") {
          setTreatments(ultraThinTreatments);
        } else {
          setTreatments(driveZClearSightTreatments);
        }
        break;
      case "clearSight":
        if (
          techContext?.technology[0].value === "Ace Bi Focal" ||
          techContext?.technology[0].value === "Office Pro" ||
          // techContext?.technology[0].value === "Genxt" ||
          techContext?.technology[0].value === "K Bi Focal"
        ) {
          setTreatments(ultraThinTreatments);
        } else if (
          techContext?.technology[0].value === "K Bi Focal (Digital)"
        ) {
          setTreatments(kBiFocalClearSunSafeTreatments);
        } else if (materialContext.selectedMaterial === "polycarbonate") {
          setTreatments(clearTransitionTreatments);
        } else if (
          (materialContext.selectedMaterial === "index1.6" ||
            materialContext.selectedMaterial === "thinLens" ||
            materialContext.selectedMaterial === "classic") &&
          (techContext?.technology[0].value === "Ultima" ||
            techContext?.technology[0].value === "DT Bi Focal")
        ) {
          setTreatments(clearTransitionTreatments);
        } else if (materialContext.selectedMaterial === "index1.6") {
          setTreatments(material16Treatments);
        } else if (materialContext.selectedMaterial === "ultraThin") {
          setTreatments(ultraThinTreatments);
        } else {
          setTreatments(driveZClearSightTreatments);
        }
        break;
      case "clearSightZ":
        if (
          techContext?.technology[0].value === "Ace Bi Focal" ||
          techContext?.technology[0].value === "Office Pro" ||
          // techContext?.technology[0].value === "Genxt" ||
          techContext?.technology[0].value === "K Bi Focal"
        ) {
          setTreatments(ultraThinTreatments);
        } else if (
          techContext?.technology[0].value === "K Bi Focal (Digital)"
        ) {
          setTreatments(kBiFocalClearSunSafeTreatments);
        } else if (materialContext.selectedMaterial === "polycarbonate") {
          setTreatments(clearTransitionTreatments);
        } else if (
          (materialContext.selectedMaterial === "index1.6" ||
            materialContext.selectedMaterial === "thinLens" ||
            materialContext.selectedMaterial === "classic") &&
          (techContext?.technology[0].value === "Ultima" ||
            techContext?.technology[0].value === "DT Bi Focal")
        ) {
          setTreatments(clearTransitionTreatments);
        } else if (materialContext.selectedMaterial === "index1.6") {
          setTreatments(material16Treatments);
        } else if (materialContext.selectedMaterial === "ultraThin") {
          setTreatments(ultraThinTreatments);
        } else {
          setTreatments(driveZClearSightTreatments);
        }
        break;
    }
  };

  useEffect(() => {
    if (techContext) {
      let techData = techContext.technology;
      let sCoat = coatingContext.selectedCoating;
      let tmpTreatments = techData.filter((data) => data.key === "treatment");
      setTreatments(tmpTreatments);
      // filterByCoating(sCoat);
    }
  }, [techContext]);

  useEffect(() => {
    if (treatmentContext.selectedTreatment === "tinted") {
      setColors(allColor);
    } else if (treatmentContext.selectedTreatment === "sunSafe") {
      setColors(brownGreyColor);
    } else if (treatmentContext.selectedTreatment === "transitions") {
      if (
        (techContext?.technology[0].value === "Neo Space" ||
          techContext?.technology[0].value === "Neo Ergo" ||
          techContext?.technology[0].value === "Single Vision") &&
        materialContext.selectedMaterial === "index1.53" &&
        coatingContext.selectedCoating === "hmc"
      ) {
        setColors(geryColor);
      } else {
        setColors(brownGreyColor);
      }
    } else if (treatmentContext.selectedTreatment === "polarized") {
      setColors(brownGreyColor);
    } else if (treatmentContext.selectedTreatment === "photochromatic") {
      setColors(brownGreyColor);
    } else {
      setColors(mirrorColor);
    }
  }, [treatmentContext.selectedTreatment]);

  return (
    <div className="model-selector" style={{width:isFullWidth?"100%":"auto"}}>
      <div className="parameter-selector__scroll">
        <Poppers
          title={t('SECTION_HEADINGS.MATERIALS')}
          paramItems={lensParamsList.materials}
          isdeafultOpenChips={false}
        />
        <Poppers
          title={t('SECTION_HEADINGS.COATINGS')}
          paramItems={lensParamsList.coatings}
          isdeafultOpenChips={true}
        />
        {treatments.length > 0 && (
          <Poppers
            title={t('SECTION_HEADINGS.ADD_ONS')}
            paramItems={treatments}
            setIsEnableColor={setIsEnableColor}
            isdeafultOpenChips={true}
          />
        )}
        {isEnableColor && (
          <Poppers
            title={t('SECTION_HEADINGS.COLORS')}
            paramItems={colors}
            setIsEnableColor={setIsEnableColor}
            isdeafultOpenChips={true}
          />
        )}
      </div>
    </div>
  );
};

export default LensModels;
