import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import { TechProvider } from "./context/technologyContext";
import { CoatingProvider } from "./context/coatingContext";
import { MaterialProvider } from "./context/materialContext";
import { TreatmentProvider } from "./context/treatmentContext";
import { ColorProvider } from "./context/colorContext";
import { InfoAnimProvider } from "./context/infoAnimContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { CameraProvider } from "./context/cameraContext";
import { CategoryProvider } from "./context/selectedLensCategory";
import {
  PublicClientApplication,
  AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { Browser } from "@capacitor/browser"; // Import Capacitor Browser Plugin
import "./i18n";

export const msalInstance = new PublicClientApplication(msalConfig);

// Function to handle login using redirect
const loginWithRedirect = async () => {
  try {
    await msalInstance.loginRedirect({
      scopes: ["user.read"],
      redirectUri: "https://titanpoc.nsplustech.com/", // Replace with your app scheme
    });
  } catch (error) {
    console.error("Login failed", error);
  }
};

// Function to handle redirect response after authentication
const handleRedirect = async () => {
  try {
    const response = await msalInstance.handleRedirectPromise();
    if (response) {
      msalInstance.setActiveAccount(response.account);
    }
  } catch (error) {
    console.error("Error handling redirect", error);
  }
};

// Initialize authentication flow
msalInstance.initialize().then(() => {
  handleRedirect(); // Handle redirect responses

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  } else {
    loginWithRedirect(); // Use redirect login for Capacitor
  }

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <CameraProvider>
        <TechProvider>
          <CategoryProvider>
            <MaterialProvider>
              <CoatingProvider>
                <TreatmentProvider>
                  <ColorProvider>
                    <InfoAnimProvider>
                      {msalInstance.getAllAccounts().length > 0 && (
                        <App pca={msalInstance} />
                      )}
                    </InfoAnimProvider>
                  </ColorProvider>
                </TreatmentProvider>
              </CoatingProvider>
            </MaterialProvider>
          </CategoryProvider>
        </TechProvider>
      </CameraProvider>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
