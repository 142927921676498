
//Titan SSO info
// export const msalConfig = {
//     auth: {
//       clientId: "9100db65-4a2c-4cc1-8457-2a9c6e5205d0",
//       authority: "https://login.microsoftonline.com/7cc91c38-648e-4ce2-a4e4-517ae39fc189",
//       redirectUri: "https://titanpoc.nsplustech.com/", // Custom redirect for mobile
//     },
//     cache: {
//       cacheLocation: "localStorage",
//       storeAuthStateInCookie: true,
//     },
//   };

//NSP SSO Info
export const msalConfig = {
    auth: {
      clientId: "e0871d4c-3965-4a3d-a514-e7450ee9c1e4",
      authority: "https://login.microsoftonline.com/544ac80a-dd11-49de-bd90-692127c9bff6",
      redirectUri: "https://titanpoc.nsplustech.com/", // Custom redirect for mobile
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  };
  